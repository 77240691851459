<template>
  <!--  <v-menu offset-y offset-x content-class="home&#45;&#45;popup-container">-->
  <!--    <template v-slot:activator="{ on, attrs }">-->
  <!--      <div class="type&#45;&#45;dropdown-item" v-bind="attrs" v-on="on">-->
  <!--        <div>{{ title }}</div>-->
  <!--        <i class="ion-ios-arrow-down ml-2"></i>-->
  <!--      </div>-->
  <!--    </template>-->
  <!--    <div class="all&#45;&#45;property-type">-->
  <!--      <router-link-->
  <!--        :to="-->
  <!--          `/${listingType === 'R' ? 'rent' : 'buy'}/search/all?property_type_category_group_id=${-->
  <!--            title === $t('general.RESIDENTIAL') ? '2' : '1'-->
  <!--          }`-->
  <!--        "-->
  <!--      >-->
  <!--        {{ `${$t('home.seeAll')} ${title} >` }}-->
  <!--      </router-link>-->
  <!--    </div>-->
  <!--    <div class="d-flex">-->
  <!--      <div class="property&#45;&#45;types-wrapper">-->
  <!--        <router-link-->
  <!--          :to="`/${listingType === 'R' ? 'rent' : 'buy'}/search${content.url}`"-->
  <!--          class="property&#45;&#45;type-item"-->
  <!--          v-for="(content, index) in propertyTypes"-->
  <!--          :key="`banner-property-type-${index}`"-->
  <!--        >-->
  <!--          <div class="img&#45;&#45;wrapper">-->
  <!--            <img :src="content.img" :alt="content.name" />-->
  <!--          </div>-->
  <!--          <div>{{ content.name }}</div>-->
  <!--        </router-link>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </v-menu>-->
  <v-select
    v-model="activePropertyTypes"
    @input="inputFilterHandler"
    :items="propertyTypes"
    :item-text="propertyTypeLabel"
    item-value="id"
    :label="title"
    class="basic--dropdown black--label"
    style="width: calc(50% - 16px); margin: 0 8px;"
    hide-details
    single-line
    solo
    multiple
    return-object
  >
    <template v-slot:selection="{ item, index }">
      <span v-if="index === 0">{{ selectedPropertyTypes.length }} {{ title }}(s)</span>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'header-user',
  props: ['listingType', 'propertyTypes', 'title'],
  data: () => ({}),
  computed: {
    activePropertyTypes: {
      get() {
        return this.$store.state.v2.search.activePropertyTypes;
      },
      set(val) {
        this.$store.commit('v2/search/SET_ACTIVE_PROPERTY_TYPES', val);
      },
    },
    selectedPropertyTypes() {
      let selected = [];
      if (this.activePropertyTypes && this.propertyTypes) {
        selected = this.activePropertyTypes.filter(item => {
          return this.propertyTypes.some(propertyType => propertyType.id === item.id);
        });
      }
      return selected;
    },
  },
  mounted() {},
  methods: {
    inputFilterHandler() {
      this.$store.commit('v2/search/SET_PAGE', 1);
    },
    propertyTypeLabel(value) {
      return value.name === 'general.allPropertyTypes' ? this.$i18n.t(`${value.name}`) : value.name;
    },
  },
};
</script>
